.logo {
    max-width: 100%;
    height: auto;
    display: block;
    /* box-shadow: 15px 15px 0 #b9ccdd; */
}

.heading {
    padding: 0 0.5em 20px;
    font-size: 26px;
    background-image: repeating-linear-gradient(
        -45deg,
        #e5c046 0px, #e5c046 2px,
        rgba(0 0 0 / 0) 0%, rgba(0 0 0 / 0) 50%
        );
    background-size: 8px 8px;
    background-repeat: repeat-x;
    background-position: center bottom;
}

.subheading {
    position: relative;
    padding: 1rem 2rem;
    border-bottom: 6px solid rgb(255, 179, 0);
  }
  
.subheading:before {
    position: absolute;
    bottom: -6px;
    left: 0;
    width: 20%;
    height: 6px;
    content: '';
    background: #f6dd00;
  }

.face-photo {
    width: 200px;
    border-radius: 25% 10%;
    box-shadow: 15px 15px 0 #004891d8
}